/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'MyApproval',
  components: {
    Scroll: Scroll
  },
  data: function data() {
    return {
      getBarWidth: '0.5rem',
      tabList: [{
        title: '待处理',
        value: 1
      }, {
        title: '已处理',
        value: 2
      }],
      active: this.$utils.Store.getItem('approvalItem') ? this.$utils.Store.getItem('approvalItem') : 0,
      list: [],
      pageNum: 20,
      //每页条数
      lx: this.$utils.Store.getItem('approvalLx') ? this.$utils.Store.getItem('approvalLx') : '0,1',
      //申请类型  0,1表示审批中  2,3表示已审批
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      initFlag: true,
      curPage: 1,
      //当前页
      totalPage: 0 //总页数

    };
  },
  computed: {},
  methods: {
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.list = [];
      this.curPage = 1;

      if (this.lx == '0,1') {
        this.getMyApproval();
      } else if (this.lx == '2,3') {
        this.getMyApproved();
      }
    },
    onPullingUp: function onPullingUp() {
      if (this.isPullUp) return;
      this.isPullUp = true;
      this.curPage++;

      if (this.curPage > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.lx == '0,1') {
        this.getMyApproval();
      } else if (this.lx == '2,3') {
        this.getMyApproved();
      }
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    getMyApproval: function getMyApproval() {
      var _this = this;

      //待审核---接口---lwt换了接口
      this.emptyShow = false;
      this.$utils.Tools.loadShow();
      this.$api.Appr.approveList({
        pageIndex: this.curPage,
        pageSize: this.pageNum,
        search: {
          approveSign: 0,
          //0待审核
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code !== _this.$code.success) {
          if (+code === 605) {
            if (_this.list.length === 0) {
              _this.emptyShow = true;
            }
          }

          _this.updateScroll(false);
        } else {
          var _this$list;

          (_this$list = _this.list).push.apply(_this$list, _toConsumableArray(data.records));

          _this.totalPage = data.totalPage;

          if (_this.list.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getMyApproved: function getMyApproved() {
      var _this2 = this;

      //已审核---换了接口
      this.emptyShow = false;
      this.$utils.Tools.loadShow();
      this.$api.Appr.approveList({
        pageIndex: this.curPage,
        pageSize: this.pageNum,
        search: {
          // lx : this.lx,
          approveSign: 1,
          //已待审核
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            data = response.data;

        if (+code !== _this2.$code.success) {
          if (+code === 605) {
            if (_this2.list.length === 0) {
              _this2.emptyShow = true;
            }
          }

          _this2.updateScroll(false);
        } else {
          var _this2$list;

          (_this2$list = _this2.list).push.apply(_this2$list, _toConsumableArray(data.records));

          _this2.totalPage = data.totalPage;

          if (_this2.list.length === 0) {
            _this2.emptyShow = true;
          }

          _this2.updateScroll();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    onItemClick: function onItemClick(index) {
      this.active = index;
      this.$utils.Store.setItem('approvalItem', this.active);

      if (index == 0) {
        this.lx = '0,1';
        this.curPage = 1;
        this.list = [];
        this.$utils.Store.setItem('approvalLx', this.lx);
        this.getMyApproval();
      } else if (index == 1) {
        this.lx = '2,3';
        this.curPage = 1;
        this.list = [];
        this.$utils.Store.setItem('approvalLx', this.lx);
        this.getMyApproved();
      }
    },
    cellClick: function cellClick(xh, type) {
      //type:假类   0 请假；1 出差；2 外出；3 加班；4 调班；5 补出勤; 6 合同 8销假
      var state = encodeURI(xh + ',2');

      switch (type //genre：类型  1：申请的  2：审批的  3：抄送的
      ) {
        case 0:
          this.$router.push({
            name: 'leaveInfo',
            params: {
              state: state
            }
          });
          break;

        case 1:
          this.$router.push({
            name: 'evectionInfo',
            params: {
              state: state
            }
          });
          break;

        case 2:
          this.$router.push({
            name: 'businessInfo',
            params: {
              state: state
            }
          });
          break;

        case 3:
          this.$router.push({
            name: 'overtimeInfo',
            params: {
              state: state
            }
          });
          break;

        case 4:
          this.$router.push({
            name: 'changeInfo',
            params: {
              state: state
            }
          });
          break;

        case 5:
          this.$router.push({
            name: 'repairInfos',
            params: {
              state: state
            }
          });
          break;

        case 6:
          this.$router.push({
            name: 'legWorkInfo',
            params: {
              state: state
            }
          });
          break;

        case 8:
          this.$router.push({
            name: 'callInfo',
            params: {
              state: state
            }
          });
          break;
      }
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    if (this.lx == '0,1') {
      this.getMyApproval();
    } else {
      this.getMyApproved();
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};